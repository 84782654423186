import React, { useRef, useState, useEffect } from "react"

import Recaptcha from "react-google-invisible-recaptcha"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import ArrowForwardIcon from "@material-ui/icons/ArrowBack"
// import ReactDOM from 'react-dom';
import useDynamicRefs from "use-dynamic-refs"

const axios = require("axios")

const useStyles = makeStyles({
  root: {
    backgroundColor: "none",
    border: "1px #fff solid",
    color: "#fff",
    width: "100%",
    marginTop: "20px",
    borderRadius: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontFamily: "AvenirMedium",
    fontSize: "0.7rem",
    padding: "5px 10px 25px 10px",
    "&:hover": {
      background: "#144D7F",
      color: "#fff",
    },
  },
})

const RequestApraisal = props => {
  // const closeAppraisal = props.closeAppraisal;
  // let holder
  const closeButton = props.closeButton
  // const overlay = props.overlay;
  const changeAppraisal = props.changeAppraisal
  const classes = useStyles(props)
  const recaptcha = useRef(null)
  const [nameValue, setNameValue] = useState("")
  const [addressValue, setAddressValue] = useState("")
  const [phoneValue, setPhoneValue] = useState("")
  const [messageSent, setMessageSent] = useState(false)
  const [showAppraisal, setShowAppraisal] = useState(true) //useState(props.closeButton);
  const updateAppraisalOverlay = props.updateAppraisalOverlay
  const [getRef, setRef] = useDynamicRefs()
  const [getFieldFocus, setFieldFocus] = useState("")
  const [inputError, setInputError] = useState(false)
  const handleFileSubmitApproved = event => {
    // calling handleFileSubmitComplete() from here means of state data is lost
  }

  useEffect(() => {
    // maintain focus on form field
    if (getFieldFocus) {
      const field = getRef(getFieldFocus)

      if (field.current) {
        field.current.focus()

        // console.log(field.current)
      }
    }
  })

  const ShowAppraisalInfo = () => {
    if (showAppraisal) {
      // holder.setStyle.background = 'rgba(0,98,169, 0.8)',

      return (
        <Grid container>
          <Grid item className="homepage_box_copy1">
            Request a current market appraisal of your property
          </Grid>
          <Grid item className="homepage_box_copy2">
            Our service starts with a free consultation, during which we will
            listen to your needs, advise you of the value of your property and
            outline how we will sell or let it, for the best possible price.
            Simply fill in the below form with your name, property address and
            phone number and we will be in touch.
          </Grid>

          {inputError && (
            <Grid item className="homepage_box_copy2">
              <b>Please enter a phone number or email address</b>
            </Grid>
          )}

          <Grid container className="homepage_box_copy3">
            <Grid item className="appraisal_row row_spacing">
              <Grid className="appraisal_header" item>
                NAME
              </Grid>

              <Grid item>
                <input
                  aria-label="Name"
                  className="appraisal_form_field"
                  id="nameInput"
                  value={nameValue}
                  ref={setRef("form_field_1")}
                  onChange={event => {
                    setFieldFocus("form_field_1")
                    setNameValue(event.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid item className="appraisal_row row_spacing">
              <Grid className="appraisal_header" item>
                ADDRESS
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <input
                  aria-label="Email"
                  className="appraisal_form_field"
                  id="addressInput"
                  value={addressValue}
                  ref={setRef("form_field_2")}
                  onChange={event => {
                    setFieldFocus("form_field_2")
                    setAddressValue(event.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid item className="appraisal_row row_spacing">
              <Grid className="appraisal_header" item>
                PHONE
              </Grid>

              <Grid item>
                <input
                  aria-label="Phone"
                  className="appraisal_form_field"
                  id="phoneInput"
                  value={phoneValue}
                  ref={setRef("form_field_3")}
                  onChange={event => {
                    setFieldFocus("form_field_3")
                    setPhoneValue(event.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid item className="appraisal_row row_end">
              <Button className={classes.root} onClick={handleFileSubmit}>
                <div>SUBMIT</div>
                <div>></div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    } else {
      // holder.setStyle.background = 'rgba(0,98,169, 0)',

      return null
    }
  }

  const ShowCloseButton = () => {
    if (showAppraisal) {
      updateAppraisalOverlay(false)
    } else {
      updateAppraisalOverlay(true)
    }

    if (showAppraisal) {
      // holder.setStyle.background = 'rgba(0,98,169, 0.8)'
      return (
        <Grid item onClick={updateAppraisal} className="homepage1b_close">
          <CloseIcon />
        </Grid>
      )
    } else {
      // holder.setStyle.background = 'rgba(0,98,169, 0)'
      return (
        <Grid item onClick={updateAppraisal} className="homepage1b_close_2">
          <ArrowForwardIcon />
        </Grid>
      )
    }
  }

  const handleFileSubmit = async e => {
    if (!phoneValue && !addressValue) {
      setInputError(true)

      return
    }

    const token = await recaptcha.current.execute()

    console.log(token)

    if (token) {
      axios
        .post("https://db.brownandkay-ejfw.temp-dns.com/recaptcha.php", {
          secret: process.env.GATSBY_CAPTCHA_SECRETKEY,
          response: token,
        })
        .then(response => {
          console.log("result " + response.data)

          if (response.data > 0.5) {
            // handleFileSubmitComplete();

            // console.log('submit!!!!'+response.data)

            axios
              .post("https://db.brownandkay-ejfw.temp-dns.com/mail.php", {
                name: nameValue,
                address: addressValue,
                phone: phoneValue,
              })
              .then(function(response) {
                // console.log(response);
                // console.log(response.data.is_valid);
                // console.log(response.data.confirmation_message);
                // setConfirmationValue(response.data.confirmation_message)
                // console.log(response)

                setMessageSent(true)

                {
                  /*. display confirmation / hide form / validate email   */
                }
              })
              .catch(function(error) {
                // console.log('-------------')
                console.log(error)
                // setConfirmationValue('There was a problem submitting your form. Please try again')
                // setSubmittedCheckFailedStatus('There was a problem submitting your form. Please try again')
              })
          } else {
            // setSubmittedCheckFailedStatus("Google Recaptcha failed. Please try again")
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  // const handleFileSubmitComplete = () => {}

  const updateAppraisal = () => {
    setShowAppraisal(prevApp => !prevApp)

    changeAppraisal()
  }

  return (
    <React.Fragment>
      <React.Fragment>{closeButton && <ShowCloseButton />}</React.Fragment>

      <React.Fragment>
        {!messageSent ? (
          <ShowAppraisalInfo />
        ) : (
          <Grid container>
            <Grid item className="homepage_box_copy1">
              Thankyou for your message.
            </Grid>
          </Grid>
        )}
      </React.Fragment>

      <Recaptcha
        ref={recaptcha}
        sitekey={process.env.GATSBY_CAPTCHA_SITEKEY}
        onResolved={handleFileSubmitApproved}
        style={{ opacity: 0 }}
      />
    </React.Fragment>
  )
}

export default RequestApraisal
